import React, {useEffect, useState} from 'react';

import '../css/App.css';

import {Helmet} from "react-helmet";

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import FeaturedPost from "../components/FeaturedPost";

import { Puff } from 'react-loading-icons';

import firebase from "firebase";
import {CSSTransition} from "react-transition-group";


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    },
}));


const Redesigns = (props) => {
    const db = firebase.firestore();
    const projectsRef = db.collection("redesigns");
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState(null);

    useEffect(() => {
        projectsRef.get().then((querySnapshot) => {
            if(querySnapshot.docs.length === 0) {
                setData(false);
                setLoading(false);
            }else{
                setData(querySnapshot.docs);
                setLoading(false);
            }
        });
    }, []);

    const classes = useStyles();

    return (
        <React.Fragment>
            <Helmet>
                <title>Редезайны</title>
                <meta name="description" content={'НФ Редизайны'} />
            </Helmet>
            {loading &&
            <CSSTransition
                timeout={300}
                classNames="page"
                unmountOnExit
                in={loading}
            >
                <div className={classes.mainGrid} align="center">
                    <Puff
                        fill="transparent"
                        fillOpacity={1}
                        height="3em"
                        speed={1}
                        stroke="#000000"
                        strokeOpacity={1}
                        strokeWidth={2}
                    />
                </div>
            </CSSTransition>
            }
            {data &&
            <CSSTransition
                timeout={300}
                classNames="page"
                unmountOnExit
                in={!loading}
            >
                <Grid className={classes.mainGrid} container spacing={4}>
                    {data.map((post) => (
                        <FeaturedPost key={post.id} post={post.data()} />
                    ))}
                </Grid>
            </CSSTransition>
            }
            {data === false &&
            <CSSTransition
                timeout={300}
                classNames="page"
                unmountOnExit
                in={!loading}
            >
                <h2 className={classes.mainGrid} align={"center"}>
                    В разработке
                </h2>
            </CSSTransition>}

        </React.Fragment>
    );

};
export default Redesigns;
