import '../css/App.css';


const NotFound = (props) => {
    return (
        <div className="App">
            <header className="App-header">
                {/*<img src={logo} className="App-logo" alt="logo" />*/}
                <div className="shadow-point" id="1"/>
                <p>
                   404
                </p>
            </header>
        </div>
    );

};
export default NotFound;
