import React, {useEffect, useState} from 'react';

import '../css/App.css';

import {Redirect, useParams} from "react-router-dom";

import {Helmet} from "react-helmet";

import { makeStyles } from '@material-ui/core/styles';

import {Puff} from "react-loading-icons";

import {CSSTransition} from "react-transition-group";

import firebase from "firebase";


import Markdown from 'markdown-to-jsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { Link as RouterLink } from 'react-router-dom';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        ...theme.typography.body2,
    },
}));

const styles = (theme) => ({
    listItem: {
        marginTop: theme.spacing(1),
    },
});

const options = {
    overrides: {
        h1: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h5',
            },
        },
        h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
        h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
        h4: {
            component: Typography,
            props: { gutterBottom: true, variant: 'caption', paragraph: true },
        },
        p: { component: Typography, props: { paragraph: true } },
        a: { component: Link },
        li: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <li className={classes.listItem}>
                    <Typography component="span" {...props} />
                </li>
            )),
        },
    },
};


const Post = (props) => {

    let { name } = useParams();

    let type = props.type;
    let destDir;
    let destDB;
    let typeName;
    let typeOneName;

    switch (type) {
        case 'project':
            destDir = 'Projects';
            destDB = 'projects';
            typeName = 'Проекты';
            typeOneName = 'Проект';
            break;
        case 'rate':
            destDir = 'Rates';
            destDB = 'redesigns';
            typeName = 'Оценка сайтов';
            typeOneName = 'Оценка сайта';
            break;
        case 'redesign':
            destDir = 'Redesigns';
            destDB = 'redesigns';
            typeName = 'Редизайны';
            typeOneName = 'Редизайн';
            break;
    }



    const storage = firebase.storage();

    const db = firebase.firestore();
    const docRef = db.collection(destDB).where("url","==",name);
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState(null);
    const [meta,setMeta] = useState(null);
    const [ts,setTs] = useState(null);
    const [error,setError] = useState(false);

    useEffect(() => {
        docRef.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setMeta(doc.data());
                    setTs(new Date(doc.data().created).toLocaleString());
                });
            });

        storage.ref(destDir+'/'+name+'/main.md').getDownloadURL()
            .then((url) => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = "text";
                xhr.onload = (event) => {
                    setData(xhr.responseText);
                    setLoading(false);
                };
                xhr.open('GET', url);
                xhr.send();
            }).catch((error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case 'storage/object-not-found':
                    setError(true);
                    setLoading(false);
                    break;
            }
        });
    }, []);



    const classes = useStyles();

    return (
        <React.Fragment>
            {loading && <div className={classes.mainGrid} align="center">
                <Puff
                    fill="transparent"
                    fillOpacity={1}
                    height="3em"
                    speed={1}
                    stroke="#000000"
                    strokeOpacity={1}
                    strokeWidth={2}
                />
            </div>}
            {data &&
            <>
                <Helmet>
                    <title>{typeOneName} {name}</title>
                    <meta name="description" content={meta.description} />
                    <meta name="keywords" content={meta.keywords} />
                    <meta property="profile:username" content="@NF" />
                    <meta name="article:tag" content={meta.keywords} />
                    <meta property="article:author" content="@NF" />
                    <meta property="og:title" content={typeOneName + ' ' + name} />
                    <meta property="og:locale" content="ru_RU" />
                    <meta property="og:image" content={meta.image} />
                    <meta property="og:description" content={meta.description} />
                    <meta property="og:type" content="article" />
                    <meta property="og:site_name" content="NF"/>
                    <meta property="twitter:title" content={typeOneName + ' ' + name} />
                    <meta property="twitter:site" content="@NF" />
                    <meta property="twitter:description" content={meta.description} />
                    <meta property="twitter:image" content={meta.image} />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="article:published_time" content={ts} />
                </Helmet>
                <div className={classes.mainGrid}>
                    <CSSTransition
                        timeout={300}
                        classNames="page"
                        unmountOnExit
                        in={!loading}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" to="/" component={RouterLink}>
                                Главная
                            </Link>
                            <Link color="inherit" to={'/'+destDir} component={RouterLink}>
                                {typeName}
                            </Link>
                            <Typography color="textPrimary">{name}</Typography>
                        </Breadcrumbs>
                    </CSSTransition>
                    <CSSTransition
                        timeout={300}
                        classNames="page"
                        unmountOnExit
                        in={!loading}
                    >
                        <Markdown options={options} >{data}</Markdown>
                    </CSSTransition>
                </div>
            </>
            }
            {error && <Redirect to={"/"} />}

        </React.Fragment>
    );

};
export default Post;
