import React, {useEffect, useState} from "react";

import { Switch, Route } from "react-router-dom";

import LoadingScreen from "react-loading-screen";

import Logo from "./logo_white.svg";

import {CSSTransition, TransitionGroup} from 'react-transition-group';

import Main from "./pages/main";
import Projects from "./pages/projects";
import NotFound from "./pages/NotFound";
import Redesigns from "./pages/redesigns";
import Rates from "./pages/rates";
import About from "./pages/about";
import Post from "./pages/post";

import {createTheme, MuiThemeProvider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Header from "./components/Header";
import Footer from "./components/Footer";

import './css/App.css';

import { useLocation } from 'react-router-dom';



const App = (props) => {
    //Loading
    const [loading,setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {  setLoading(false); }, 500);
    }, []);

    const THEME = createTheme({
        palette: {
            type: 'light',
        },
        typography: {
            "fontFamily": `'Comfortaa', cursive`,
            "fontWeightLight": 300,
            "fontWeightRegular": 400,
            "fontWeightMedium": 500
        }
    });

    const location = useLocation();

  return (
      <MuiThemeProvider theme={THEME}>
          <LoadingScreen
              loading={loading}
              bgColor='#000000'
              logoSrc={Logo}
          >
                      <Container maxWidth="lg" >

                          <Header />
                          <TransitionGroup>
                              <CSSTransition
                                  timeout={300}
                                  classNames="page"
                                  unmountOnExit
                                  key={location.key}
                              >
                                  <Switch location={location}>
                                      <Route exact path="/">
                                          <Main fb={props.fb}/>
                                      </Route>
                                      <Route exact path="/projects">
                                          <Projects fb={props.fb}/>
                                      </Route>
                                      <Route exact path="/redesigns">
                                          <Redesigns fb={props.fb}/>
                                      </Route>
                                      <Route exact path="/rates">
                                          <Rates fb={props.fb}/>
                                      </Route>
                                      <Route exact path="/about_author">
                                          <About fb={props.fb}/>
                                      </Route>
                                      <Route exact path="/project/:name">
                                          <Post type={"project"}/>
                                      </Route>
                                      <Route exact path="/rate/:name">
                                          <Post type={"rate"}/>
                                      </Route>
                                      <Route exact path="/redesign/:name">
                                          <Post type={"redesign"}/>
                                      </Route>
                                      <Route>
                                          <NotFound/>
                                      </Route>
                                  </Switch>
                              </CSSTransition>
                          </TransitionGroup>
                      </Container>
                  <Footer className="fixed-bottom" />
          </LoadingScreen>
      </MuiThemeProvider>
  );
}

export default App;
