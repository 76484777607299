import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase";
import "firebase/analytics";
import {BrowserRouter} from "react-router-dom";


const firebaseConfig = {
    apiKey: "AIzaSyCAb8v8DaTVio2zdWnyp3Rh-Fd1hN6x-DM",
    authDomain: "nfsite-345a9.firebaseapp.com",
    projectId: "nfsite-345a9",
    storageBucket: "nfsite-345a9.appspot.com",
    messagingSenderId: "548773700911",
    appId: "1:548773700911:web:2134486e8cbc16b43eedda",
    measurementId: "G-BND2V471NX"
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();


ReactDOM.render(
  <React.Fragment>
      <BrowserRouter>
        <App fb={firebase}/>
      </BrowserRouter>
  </React.Fragment>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
