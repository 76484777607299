import React from 'react';

const Logo = ({fill,height}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
        clipRule="evenodd"
        viewBox="0 0 212 97"
        height={height}
    >
        <g fillRule="nonzero" fill={fill}>
            <path
                d="M247.293 187.371c-1.206-.603-2.471-.723-3.797-.361-1.326.421-2.32 1.235-2.983 2.44-.603 1.206-.723 2.471-.362 3.797.422 1.326 1.236 2.32 2.441 2.983 6.509 3.435 11.782 8.196 15.82 14.283 3.977 6.027 6.298 12.656 6.961 19.888a5.07 5.07 0 00-1.899-.362h-83.98c.723-7.171 3.073-13.74 7.051-19.707 4.038-6.026 9.251-10.727 15.639-14.102 1.265-.663 2.079-1.657 2.441-2.983.421-1.326.301-2.591-.362-3.797-.603-1.205-1.567-2.019-2.893-2.44-1.326-.362-2.591-.242-3.797.361-8.738 4.641-15.669 11.179-20.791 19.617-5.063 8.437-7.594 17.597-7.594 27.481 0 10.305 2.803 19.827 8.407 28.566 5.605 8.799 13.108 15.368 22.51 19.707a4.974 4.974 0 002.079.452c2.109 0 3.616-.964 4.52-2.893.602-1.265.663-2.531.18-3.797-.482-1.325-1.355-2.26-2.621-2.802-6.75-3.134-12.324-7.714-16.724-13.741-4.399-6.026-7.081-12.686-8.045-19.978h83.98c.603 0 1.236-.12 1.899-.361-.904 7.352-3.556 14.072-7.956 20.159-4.399 6.086-10.004 10.727-16.814 13.921-1.265.542-2.139 1.477-2.621 2.802-.482 1.266-.422 2.532.18 3.797.904 1.929 2.411 2.893 4.52 2.893.724 0 1.417-.151 2.08-.452 9.401-4.339 16.904-10.908 22.509-19.707 5.605-8.739 8.407-18.261 8.407-28.566 0-9.884-2.561-19.044-7.684-27.481-5.062-8.438-11.963-14.976-20.701-19.617zM337.692 197.225c9.401 1.265 17.266 5.454 23.594 12.565 6.388 7.232 9.582 15.639 9.582 25.221 0 9.643-3.194 18.08-9.582 25.312-6.328 7.111-14.193 11.3-23.594 12.565v-75.663zm-43.211 37.786c0-9.582 3.194-17.989 9.582-25.221 6.328-7.111 14.193-11.3 23.594-12.565v75.663c-9.401-1.265-17.266-5.454-23.594-12.565-6.388-7.232-9.582-15.669-9.582-25.312zm38.239-48.092c-13.319 0-24.679 4.701-34.081 14.102-9.401 9.402-14.102 20.732-14.102 33.99 0 13.319 4.701 24.679 14.102 34.081 9.402 9.401 20.762 14.102 34.081 14.102 13.258 0 24.588-4.701 33.99-14.102 9.401-9.402 14.102-20.762 14.102-34.081 0-13.258-4.701-24.588-14.102-33.99-9.402-9.401-20.732-14.102-33.99-14.102z"
                transform="translate(-169.188 -186.806)"
            ></path>
        </g>
    </svg>
);

export default Logo;