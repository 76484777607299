import React, {useEffect, useState} from 'react';

import {Helmet} from "react-helmet";

import { makeStyles } from '@material-ui/core/styles';

import MainFeaturedPost from "../components/MainPost";

import {CSSTransition} from "react-transition-group";

import '../css/App.css';
import firebase from "firebase";


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    },
}));

const mainFeaturedPost = [
    {
        title: 'Всё только начинается',
        description:
            "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
        image: 'https://source.unsplash.com/random',
        imgText: 'main image description',
        link: '/projects/aogjaskskas',
    },
    {
        title: 'Title of a longer featured blog post 2',
        description:
            "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
        image: 'https://source.unsplash.com/random',
        imgText: 'main image description',
        link: '/projects/aogjaskskas',
    },
    {
        title: 'Title of a longer featured blog post 3',
        description:
            "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
        image: 'https://source.unsplash.com/random',
        imgText: 'main image description',
        link: '/projects/aogjaskskas',
    },
];


const Main = (props) => {
    const classes = useStyles();

    const db = firebase.firestore();
    const mainRef = db.collection("main");

    const [loading,setLoading] = useState(true);
    const [data,setData] = useState(null);

    useEffect(() => {
        mainRef.get().then((querySnapshot) => {
            if(querySnapshot.docs.length === 0) {
                setData(false);
                setLoading(false);
            }else{
                setData(querySnapshot.docs[0].data());
                setLoading(false);
            }
        });
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>Главная</title>
                <meta name="description" content="Главная страница сайта НФ" />
            </Helmet>
            {data &&
            <CSSTransition
                timeout={300}
                classNames="page"
                unmountOnExit
                in={!loading}
            >
                <MainFeaturedPost className={classes.mainGrid} post={data}/>
            </CSSTransition>
            }
        </React.Fragment>
    );
};
export default Main;
