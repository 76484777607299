import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from "react-router-dom";
import Logo from "./Logo";




const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(8),
        padding: theme.spacing(6, 0),
    },
}));

export default function Footer(props) {
    const classes = useStyles();

    function Copyright() {
        return (
            <Typography variant="body1" color="textSecondary" align="center">
                {'Copyright © '}
                <Link to={"/"} component={RouterLink} color="inherit">
                    <Logo fill={"rgba(0, 0, 0, 0.54)"} height="2vmin" />
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    }

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Copyright />
            </Container>
        </footer>
    );
}

Footer.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
};