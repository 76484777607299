import React from 'react';

import '../css/App.css';

import {Helmet} from "react-helmet";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import FeaturedPost from "../components/FeaturedPost";
import {CSSTransition} from "react-transition-group";


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    },
}));


const About = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Helmet>
                <title>Об авторе</title>
                <meta name="description" content={'НФ Об авторе'} />
            </Helmet>

            <CSSTransition
                timeout={300}
                classNames="page"
                unmountOnExit
                in={true}
            >
                <h2 className={classes.mainGrid} align={"center"}>
                    Беру идеи и воплощаю их в жизнь © НФ
                </h2>
            </CSSTransition>
        </React.Fragment>
    );

};
export default About;
